import React from "react"
import footerStyles from "../../styles/footer/footer.module.scss"
import { Link } from "gatsby"
import EmailIcon from "../../assets/mail.svg"
import InstagramIcon from "../../assets/instagram.svg"
import FacebookIcon from "../../assets/facebook.svg"
import WhatsappIcon from "../../assets/whatsapp.svg"

const Footer = () => {
  return (
    <>
        <div className={footerStyles.footer}>
          <div className={footerStyles.wrapper}>
        <div className={footerStyles.social}>
            <a
                href="mailto:info@lamurrinaborgomanero.it"
                target="_blank"
                rel="noreferrer"
              >
               <EmailIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://it-it.facebook.com/lamurrinaBorgomanero/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://www.instagram.com/la_murrina_/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon className={footerStyles.icon} />
              </a>
              <a
                href="https://wa.me/390322843555?text=Buongiorno%20vi%20contatto%20per%20informazioni"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsappIcon className={footerStyles.icon} />
              </a>
              </div>
              <span className={footerStyles.orari}>Orari mattino: LU-MA-ME-GIO-VE ore 9:00-12:30 e SA ore 10:00-12:30</span><br></br>
              <span className={footerStyles.orari}>Orari apertura pomeriggio: LU-GIO-SA ore 15:00, VE ore 14:00 e MA-ME chiuso</span><br></br>
              <span className={footerStyles.orari}>Orari chiusura pomeriggio: LU ore 18:30, GIO ore 19:00, VE ore 17:30 e SA ore 18:00</span>
            </div>
      
          <div className={footerStyles.contatti}>

              <div className={footerStyles.legal}>
            <Link to="/condizioni-di-vendita"><span >condizioni di vendita</span></Link>
            <Link to="/privacy-policy"><span>privacy policy</span></Link>
            <Link to="/cookie-policy"><span>cookie policy</span></Link>
          </div>
          <div className={footerStyles.address}>
          <span >
              viale Zoppis 31/A - 28021 Borgomanero (NO)
              </span>
              <span >
                info@lamurrinaborgomanero.it
              </span>
              <span >tel/whatsapp 0322 843555</span>
              </div>
            </div>

          </div>
      <p className={footerStyles.copy}>{`© ${new Date().getFullYear()} La Murrina Borgomanero - V. & T. di Vinzio Laura - P.IVA 02111410037 - Tutti i diritti riservati`}</p>
    </>
  )
}

export default Footer 