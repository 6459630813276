import React, { useState, useRef, useContext } from "react"
import { Link } from "gatsby"
import headerStyles from "../../styles/header/header.module.scss"
import CartIcon from "../../assets/cart.svg"
import Logo from "../../assets/lamurrinalogo.svg"
import  useOnClickOutside  from "../hooks/useOnClickOutside"
import { StoreContext } from "../../context/storeContext"
import Banner from "../common/banner"
import VsBanner from '../common/vsBanner'

const Header = ({ menuLinks, location }) => {
    const { cartCount } = useContext(StoreContext)
    const [open, setOpen] = useState(false)
    const node = useRef()
    useOnClickOutside(node, () => setOpen(false))

  return (
    <>
    <nav className={headerStyles.nav}>
      <VsBanner/>
      <ul className={headerStyles.display}>
        <li key='logo' className={headerStyles.logo}>
            <Link to="/"> <Logo />
            <span>| Borgomanero</span></Link>
        </li>
        <li key='carrello' className={headerStyles.linkLg}>
          <Link className={headerStyles.cartLink} to="/carrello">
            <CartIcon className={headerStyles.cartIcon} />
            <span className={cartCount < 10 ? headerStyles.cartCount : headerStyles.cartCountLarge}>{cartCount}</span>
          </Link>
        </li>
      </ul>
      <ul className={location.pathname === "/" ? headerStyles.hide : headerStyles.navigation}>        
      {menuLinks.map(link => (
        location.pathname.includes(`/${link.name}`) ? 
          <li key={link.name} className={`${headerStyles.linkLg} ${headerStyles.active}`}>
            <Link to={link.link}>{link.name.replace(/-/g, " ")}</Link>
          </li> : 
          <li key={link.name} className={headerStyles.linkLg}>
          <Link to={link.link}>{link.name.replace(/-/g, " ")}</Link>
        </li>     
        ))}
      </ul>
      {/* <VsBanner/> */}
    </nav>
    {location.pathname === "/" && <Banner />}
</>
)
}

export default Header