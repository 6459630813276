import React from "react"
import bannerStyles from "../../styles/common/banner.module.scss"
import WhatsappIcon from "../../assets/whatsapp.svg"

const VsBanner = () => {

  return (
      <div className={bannerStyles.virtualshop}>
        <p>Se una foto non basta...
        Prenota un video appuntamento di shopping virtuale via whatsapp 0322843555</p> 
{/*         <a
          href="https://wa.me/390322843555?text=Buongiorno%20vi%20contatto%20per%20informazioni"
          target="_blank"
          rel="noreferrer"
        >
          <WhatsappIcon className={bannerStyles.icon} />
        </a>   */}
      </div>
  )
}

export default VsBanner 