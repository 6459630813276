import React from "react"
import { Helmet } from "react-helmet"
import  Footer from "../footer/footer"
import Header from "../header/header"
import layoutStyles from "../../styles/common/layout.module.scss"
import { StaticQuery, graphql } from "gatsby"

const Layout = ({ pageMeta, children, location }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}

    render={data => (
      <>
        <Helmet>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <html lang="it" />
          <title>{`${pageMeta.title} | ${data.site.siteMetadata.title}`}</title>
          <meta name="description" content={pageMeta.description} />
          <meta name="keywords" content={pageMeta.keywords.join(",")} />
          <link
            rel="canonical"
            href={`https://www.lamurrinaborgomanero.it${pageMeta.link}`}
          />
        </Helmet>
        <div className={layoutStyles.page}>
           <Header
            menuLinks={data.site.siteMetadata.menuLinks}
            siteTitle={data.site.siteMetadata.title}
            location={location}
          /> 
            <div className={layoutStyles.content}>
            {children}
            </div>
          <Footer />  
         </div>
      </>
    )}
  />
)

export default Layout 
